<template>
	<CRow>
		<CCol md="7" class="align-self-center">
			<label data-test-id="title">
				{{ title }}
			</label>
		</CCol>
		<CCol md="5" class="text-right">
			<CButton
				class="btn-add-image transparent"
				color="secondary"
				data-test-id="category-add-image"
				:disabled="!!$v.value.$model"
				@click="$refs['media-library'].open()"
			>
				<CIcon name="cil-plus" />
				Add image
			</CButton>
		</CCol>
		<CCol>
			<div :class="['media-image-previews', $v.value.$error && '-is-invalid']">
				<template v-if="$v.value.$model">
					<div class="media-image">
						<a :href="$v.value.$model.imageUrls.original" target="_blank">
							<img :src="$v.value.$model.imageUrls.original" alt="">
						</a>
					</div>
					<CButton
						class="btn-delete-image"
						@click="handleDeleteImage($v.value.$model.id)"
					>
						<CIcon name="cil-trash" /> <span>Remove image</span>
					</CButton>
				</template>
				<div v-else class="media-no-image" data-test-id="media-no-image">
					<img
						src="/assets/images/product-placeholder.svg"
						alt="Empty image"
						width="40"
						class="empty-image mb-1"
					>
					<p class="typo-body-2 color-black-45">
						{{ noImageText }}
					</p>
				</div>
			</div>
			<div
				v-if="$v.value.$error"
				:class="['invalid-feedback', $v.value.$error && '-is-invalid']"
				class="invalid-feedback"
			>
				Required field
			</div>
			<ul v-if="showHelper" class="media-image-helper">
				<li>
					- Recommended resolution W{{ width }} x H{{ height }} px.
				</li>
				<li>- Maximum upload file size is 10MB</li>
				<li>- Supported PNG, JPG, JPEG.</li>
			</ul>
			<ModalMediaLibrary
				ref="media-library"
				type="single"
				@onSubmit="handleSubmitMedia"
			/>
		</CCol>
	</CRow>
</template>

<script>
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';

import { requiredIf } from 'vuelidate/lib/validators';

export default {
	name: 'BaseMediaSelectorBox',
	components: {
		ModalMediaLibrary,
	},
	validations() {
		return {
			value: {
				required: requiredIf((data) => data.required),
			},
		};
	},
	props: {
		value: {
			type: Object,
			default: null,
		},
		title: {
			type: String,
			default: 'Image',
		},
		noImageText: {
			type: String,
			default: 'No image',
		},
		required: {
			type: Boolean,
			default: false,
		},
		showHelper: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: '0',
		},
		height: {
			type: String,
			default: '0',
		},
	},
	methods: {
		handleSubmitMedia(file = []) {
			this.$emit('input', file[0]);
		},
		handleDeleteImage() {
			this.$emit('input', null);
		},
	},
};
</script>

<style lang="scss" scoped>
	.media-image-previews {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		background-color: $color-white;
		border: 1px solid $color-gray-400;
		margin-top: rem(16);
		height: rem(208);
		border-radius: 4px;
		overflow: hidden;

		.media-image {
			position: relative;
			height: 100%;

			a {
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				border-radius: 2px;
			}
		}

		&.-is-invalid {
			border: 1px solid $color-alert;
		}
	}

	.media-no-image {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-color: $color-gray-100;

		p {
			margin-bottom: 0;
		}
	}

	.media-image-helper {
		list-style: none;
		margin-top: rem(12);
		padding: 0;

		li {
			@include typo-body-2;

			color: $color-black-45;
			margin-bottom: 2px;
		}
	}

	.btn-delete-image {
		position: absolute;
		right: rem(8);
		bottom: rem(8);
		height: rem(20);
		line-height: rem(20);
		box-shadow: none;
		padding: 0 4px;
		border-radius: 2px;
		background-color: $color-black-45;
		color: $color-white-95;
		font-size: rem(12);

		svg {
			width: rem(12) !important;
			color: $color-white-95;
			margin-right: 4px;
		}

		span {
			position: relative;
			top: 1px;
		}
	}

	.invalid-feedback {
		&.-is-invalid {
			display: block;
		}
	}
</style>

<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<BannerGeneral
			:id="id"
			ref="general"
			name-label="Highlight banner name*"
			:name="name"
			:priority="priority"
			:status="status"
			:widgets="widgets"
			:widget-type="widgetType"
			show-widget
			@update:name="name = $event"
			@update:priority="priority = $event"
			@update:status="status = $event"
			@update:widgets="widgets = $event"
		/>

		<BannerDate
			ref="date"
			:status="status"
			:start-date="startDate"
			:start-time="startTime"
			:end-date="endDate"
			:end-time="endTime"
			@update:startDate="startDate = $event"
			@update:startTime="startTime = $event"
			@update:endDate="endDate = $event"
			@update:endTime="endTime = $event"
		/>

		<BannerContentWrapper
			ref="contentLeft"
			:banner-link="bannerLinkLeft"
			desktop-img="/assets/images/highlight-left-banner-desktop.svg"
			mobile-img="/assets/images/highlight-banner-mobile.svg"
			required-link
			has-title
			required-title
			:title-th="leftBannerTitleTh"
			:title-en="leftBannerTitleEn"
			has-description
			is-required-description
			:description-th="leftBannerDescriptionTh"
			:description-en="leftBannerDescriptionEn"
			has-button-text
			is-required-button-text
			:button-text-th="leftBannerButtonTextTh"
			:button-text-en="leftBannerButtonTextEn"
			has-display-price
			is-required-display-price
			:display-price="leftBannerLowestPrice"
			has-badge-label
			:badge-labels="leftBannerLabels"
			@update:badgeLabels="leftBannerLabels = $event"
			@update:bannerLink="bannerLinkLeft = $event"
			@update:titleTh="leftBannerTitleTh = $event"
			@update:titleEn="leftBannerTitleEn = $event"
			@update:descriptionTh="leftBannerDescriptionTh = $event"
			@update:descriptionEn="leftBannerDescriptionEn = $event"
			@update:buttonTextTh="leftBannerButtonTextTh = $event"
			@update:buttonTextEn="leftBannerButtonTextEn = $event"
			@update:displayPrice="leftBannerLowestPrice = $event"
		>
			<template #title>
				Left banner
			</template>
			<BaseMediaSelectorBox
				ref="leftBannerDesktop"
				v-model="leftBannerDesktop"
				title="Desktop banner"
				no-image-text="No banner"
				show-helper
				width="550"
				height="550"
				class="mt-4"
				required
			/>

			<BaseMediaSelectorBox
				ref="leftBannerMobile"
				v-model="leftBannerMobile"
				title="Mobile banner"
				no-image-text="No banner"
				show-helper
				width="362"
				height="362"
				class="mt-4"
				required
			/>
		</BannerContentWrapper>

		<BannerContentWrapper
			ref="contentTopRight"
			desktop-img="/assets/images/highlight-top-right-banner-desktop.svg"
			mobile-img="/assets/images/highlight-banner-mobile.svg"
			:required-link="!!topRightBanner"
			:banner-link="bannerLinkTopRight"
			has-title
			required-title
			:title-th="topRightBannerTitleTh"
			:title-en="topRightBannerTitleEn"
			has-description
			is-required-description
			:description-th="topRightBannerDescriptionTh"
			:description-en="topRightBannerDescriptionEn"
			has-button-text
			is-required-button-text
			:button-text-th="topRightBannerButtonTextTh"
			:button-text-en="topRightBannerButtonTextEn"
			has-display-price
			is-required-display-price
			:display-price="topRightBannerLowestPrice"
			has-badge-label
			:badge-labels="topRightBannerLabels"
			@update:badgeLabels="topRightBannerLabels = $event"
			@update:bannerLink="bannerLinkTopRight = $event"
			@update:titleTh="topRightBannerTitleTh = $event"
			@update:titleEn="topRightBannerTitleEn = $event"
			@update:descriptionTh="topRightBannerDescriptionTh = $event"
			@update:descriptionEn="topRightBannerDescriptionEn = $event"
			@update:buttonTextTh="topRightBannerButtonTextTh = $event"
			@update:buttonTextEn="topRightBannerButtonTextEn = $event"
			@update:displayPrice="topRightBannerLowestPrice = $event"
		>
			<template #title>
				Top - Right banner
			</template>
			<BaseMediaSelectorBox
				ref="topRightBanner"
				v-model="topRightBanner"
				title="Desktop banner"
				no-image-text="No banner"
				show-helper
				width="550"
				height="623"
				class="mt-3 mb-4"
				required
			/>
			<BaseMediaSelectorBox
				ref="topRightBannerMobile"
				v-model="topRightBannerMobile"
				title="Mobile banner"
				no-image-text="No banner"
				show-helper
				width="235"
				height="200"
				class="mt-4"
				required
			/>
		</BannerContentWrapper>

		<BannerContentWrapper
			ref="contentBottomRight"
			desktop-img="/assets/images/highlight-bottom-right-banner-desktop.svg"
			mobile-img="/assets/images/highlight-banner-mobile.svg"
			:required-link="!!bottomRightBanner"
			:banner-link="bannerLinkBottomRight"
			has-title
			required-title
			:title-th="bottomRightBannerTitleTh"
			:title-en="bottomRightBannerTitleEn"
			has-description
			is-required-description
			:description-th="bottomRightBannerDescriptionTh"
			:description-en="bottomRightBannerDescriptionEn"
			has-button-text
			is-required-button-text
			:button-text-th="bottomRightBannerButtonTextTh"
			:button-text-en="bottomRightBannerButtonTextEn"
			has-display-price
			is-required-display-price
			:display-price="bottomRightBannerLowestPrice"
			has-badge-label
			:badge-labels="bottomRightBannerLabels"
			@update:badgeLabels="bottomRightBannerLabels = $event"
			@update:bannerLink="bannerLinkBottomRight = $event"
			@update:titleTh="bottomRightBannerTitleTh = $event"
			@update:titleEn="bottomRightBannerTitleEn = $event"
			@update:descriptionTh="bottomRightBannerDescriptionTh = $event"
			@update:descriptionEn="bottomRightBannerDescriptionEn = $event"
			@update:buttonTextTh="bottomRightBannerButtonTextTh = $event"
			@update:buttonTextEn="bottomRightBannerButtonTextEn = $event"
			@update:displayPrice="bottomRightBannerLowestPrice = $event"
		>
			<template #title>
				Bottom - Right banner
			</template>
			<BaseMediaSelectorBox
				ref="bottomRightBanner"
				v-model="bottomRightBanner"
				title="Desktop banner"
				no-image-text="No banner"
				show-helper
				width="550"
				height="623"
				class="mt-4"
				required
			/>
			<BaseMediaSelectorBox
				ref="bottomRightBannerMobile"
				v-model="bottomRightBannerMobile"
				title="Mobile banner"
				no-image-text="No banner"
				show-helper
				width="235"
				height="200"
				class="mt-4"
				required
			/>
		</BannerContentWrapper>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading || isUpdating"
			:is-edit="isEditMode"
			:remove-text="isEditMode ? 'Remove banner' : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'BannerHighlightList'})"
			@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteBanner.bind(null, id)"
			title="Remove this banner?"
			description="By removing this, all banner info will be disappeared from the list and linked widgets."
			@onSuccess="$router.push({ name: 'BannerHighlightList'})"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import BannerGeneral from '@/components/BannerGeneral.vue';
import BannerDate from '@/components/BannerDate.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';

import { BANNER_TYPE, BANNER_IMAGE_TYPE } from '../enums/banners';
import { convertDateTimeToUTC, priceToAPI } from '../assets/js/helpers';

export default {
	name: 'BannerHighlightForm',

	components: {
		BannerGeneral,
		BannerDate,
		BannerContentWrapper,
		BaseMediaSelectorBox,
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
		bannerType: {
			type: String,
			default: BANNER_TYPE.highlight,
		},
	},

	data() {
		return {
			id: null,
			name: '',
			priority: 0,
			widgets: [],
			status: true,
			startDate: null,
			startTime: '00:00',
			endDate: null,
			endTime: '23:59',
			leftBannerDesktop: null,
			leftBannerMobile: null,
			leftBannerTitleEn: '',
			leftBannerTitleTh: '',
			leftBannerDescriptionEn: '',
			leftBannerDescriptionTh: '',
			leftBannerLowestPrice: null,
			leftBannerButtonTextEn: '',
			leftBannerButtonTextTh: '',
			topRightBanner: null,
			topRightBannerMobile: null,
			topRightBannerTitleEn: '',
			topRightBannerTitleTh: '',
			topRightBannerDescriptionEn: '',
			topRightBannerDescriptionTh: '',
			topRightBannerLowestPrice: null,
			topRightBannerButtonTextEn: '',
			topRightBannerButtonTextTh: '',
			bottomRightBanner: null,
			bottomRightBannerMobile: null,
			bottomRightBannerTitleEn: '',
			bottomRightBannerTitleTh: '',
			bottomRightBannerDescriptionEn: '',
			bottomRightBannerDescriptionTh: '',
			bottomRightBannerLowestPrice: null,
			bottomRightBannerButtonTextEn: '',
			bottomRightBannerButtonTextTh: '',
			bannerLinkLeft: '',
			bannerLinkTopRight: '',
			bannerLinkBottomRight: '',
			leftBannerLabels: [],
			topRightBannerLabels: [],
			bottomRightBannerLabels: [],
		};
	},

	computed: {
		widgetType() {
			const widget = {
				hero: 'hero_banner',
				highlight: 'highlight_banner',
				small: 'small_banner',
			};

			return widget[this.bannerType];
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (this.isEditMode && !this.isUpdating) {
					this.id = value.id;
					this.name = value.name;
					this.priority = value.priority;
					this.status = value.status;
					this.startDate = value.startDate;
					this.startTime = value.startTime;
					this.endDate = value.endDate;
					this.endTime = value.endTime;

					this.leftBannerDesktop = value.banners.leftBannerDesktop;
					this.leftBannerMobile = value.banners.leftBannerMobile;
					this.leftBannerTitleEn = value.banners.leftBannerDesktop.titleEn;
					this.leftBannerTitleTh = value.banners.leftBannerDesktop.titleTh;
					this.leftBannerDescriptionEn = value.banners.leftBannerDesktop.descriptionEn;
					this.leftBannerDescriptionTh = value.banners.leftBannerDesktop.descriptionTh;
					this.leftBannerLowestPrice = value.banners.leftBannerDesktop.lowestPrice;
					this.leftBannerButtonTextEn = value.banners.leftBannerDesktop.buttonTextEn;
					this.leftBannerButtonTextTh = value.banners.leftBannerDesktop.buttonTextTh;
					this.leftBannerLabels = value.banners.leftBannerLabels;

					this.topRightBanner = value.banners.topRightBanner;
					this.topRightBannerMobile = value.banners.topRightBannerMobile;
					this.topRightBannerTitleEn = value.banners.topRightBanner.titleEn;
					this.topRightBannerTitleTh = value.banners.topRightBanner.titleTh;
					this.topRightBannerDescriptionEn = value.banners.topRightBanner.descriptionEn;
					this.topRightBannerDescriptionTh = value.banners.topRightBanner.descriptionTh;
					this.topRightBannerLowestPrice = value.banners.topRightBanner.lowestPrice;
					this.topRightBannerButtonTextEn = value.banners.topRightBanner.buttonTextEn;
					this.topRightBannerButtonTextTh = value.banners.topRightBanner.buttonTextTh;
					this.topRightBannerLabels = value.banners.topRightBannerLabels;

					this.bottomRightBanner = value.banners.bottomRightBanner;
					this.bottomRightBannerMobile = value.banners.bottomRightBannerMobile;
					this.bottomRightBannerTitleEn = value.banners.bottomRightBanner.titleEn;
					this.bottomRightBannerTitleTh = value.banners.bottomRightBanner.titleTh;
					this.bottomRightBannerDescriptionEn = value.banners.bottomRightBanner.descriptionEn;
					this.bottomRightBannerDescriptionTh = value.banners.bottomRightBanner.descriptionTh;
					this.bottomRightBannerLowestPrice = value.banners.bottomRightBanner.lowestPrice;
					this.bottomRightBannerButtonTextEn = value.banners.bottomRightBanner.buttonTextEn;
					this.bottomRightBannerButtonTextTh = value.banners.bottomRightBanner.buttonTextTh;
					this.bottomRightBannerLabels = value.banners.bottomRightBannerLabels;

					this.bannerLinkLeft = value.banners.bannerLinkLeft;
					this.bannerLinkTopRight = value.banners.bannerLinkTopRight;
					this.bannerLinkBottomRight = value.banners.bannerLinkBottomRight;
					this.widgets = value.widgets;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteBanner: 'banners/deleteBanner',
		}),

		handleChangeEndDate(value) {
			this.endDate = value;
		},

		addBanner(file, type, link, { titleTh, titleEn, descriptionTh, descriptionEn, lowestPrice, buttonTextTh, buttonTextEn, labelIds }) {
			const result = {};
			result.file = file ? file.id : null;
			result.type = type;

			if (link) {
				result.link = link;
			}

			result.title_th = titleTh;
			result.title_en = titleEn;
			result.description_th = descriptionTh;
			result.description_en = descriptionEn;
			result.lowest_price = priceToAPI(Number(lowestPrice));
			result.button_text_th = buttonTextTh;
			result.button_text_en = buttonTextEn;
			result.label_ids = labelIds;

			return result;
		},

		async handleSubmit() {
			const generalRef = this.$refs.general.$v;
			const dateRef = this.$refs.date.$v;

			const contentLeftRef = this.$refs.contentLeft.$v;
			const leftBannerDesktopRef = this.$refs.leftBannerDesktop.$v;
			const leftBannerMobileRef = this.$refs.leftBannerMobile.$v;

			const contentTopRightRef = this.$refs.contentTopRight.$v;
			const topRightBannerRef = this.$refs.topRightBanner.$v;
			const topRightBannerMobileRef = this.$refs.topRightBannerMobile.$v;

			const contentBottomRightRef = this.$refs.contentBottomRight.$v;
			const bottomRightBannerRef = this.$refs.bottomRightBanner.$v;
			const bottomRightBannerMobileRef = this.$refs.bottomRightBannerMobile.$v;

			generalRef.$touch();
			dateRef.$touch();

			contentLeftRef.$touch();
			leftBannerDesktopRef.$touch();
			leftBannerMobileRef.$touch();

			contentTopRightRef.$touch();
			topRightBannerRef.$touch();
			topRightBannerMobileRef.$touch();

			contentBottomRightRef.$touch();
			bottomRightBannerRef.$touch();
			bottomRightBannerMobileRef.$touch();

			if (
				!generalRef.$invalid &&
				!dateRef.$invalid &&
				!contentLeftRef.$invalid &&
				!leftBannerDesktopRef.$invalid &&
				!leftBannerMobileRef.$invalid &&
				!contentTopRightRef.$invalid &&
				!topRightBannerRef.$invalid &&
				!topRightBannerMobileRef.$invalid &&
				!contentBottomRightRef.$invalid &&
				!bottomRightBannerRef.$invalid &&
				!bottomRightBannerMobileRef.$invalid
			) {
				const leftBannerContent = {
					titleTh: this.leftBannerTitleTh,
					titleEn: this.leftBannerTitleEn,
					descriptionTh: this.leftBannerDescriptionTh,
					descriptionEn: this.leftBannerDescriptionEn,
					lowestPrice: this.leftBannerLowestPrice,
					buttonTextTh: this.leftBannerButtonTextTh,
					buttonTextEn: this.leftBannerButtonTextEn,
					labelIds: this.leftBannerLabels.map((item) => item.id),
				};

				const topRightBannerContent = {
					titleTh: this.topRightBannerTitleTh,
					titleEn: this.topRightBannerTitleEn,
					descriptionTh: this.topRightBannerDescriptionTh,
					descriptionEn: this.topRightBannerDescriptionEn,
					lowestPrice: this.topRightBannerLowestPrice,
					buttonTextTh: this.topRightBannerButtonTextTh,
					buttonTextEn: this.topRightBannerButtonTextEn,
					labelIds: this.topRightBannerLabels.map((item) => item.id),
				};

				const bottomRightBannerContent = {
					titleTh: this.bottomRightBannerTitleTh,
					titleEn: this.bottomRightBannerTitleEn,
					descriptionTh: this.bottomRightBannerDescriptionTh,
					descriptionEn: this.bottomRightBannerDescriptionEn,
					lowestPrice: this.bottomRightBannerLowestPrice,
					buttonTextTh: this.bottomRightBannerButtonTextTh,
					buttonTextEn: this.bottomRightBannerButtonTextEn,
					labelIds: this.bottomRightBannerLabels.map((item) => item.id),
				};


				const data = {
					name: this.name,
					type: BANNER_TYPE.highlight,
					priority: Number(this.priority, 10),
					is_visible: this.status,
					start_at: convertDateTimeToUTC(this.startDate, this.startTime),
					end_at: convertDateTimeToUTC(this.endDate, this.endTime),
					banners: [
						this.addBanner(
							this.leftBannerDesktop,
							BANNER_IMAGE_TYPE.homepage_highlight_1_banner_image,
							this.bannerLinkLeft,
							leftBannerContent,
						),
						this.addBanner(
							this.leftBannerMobile,
							BANNER_IMAGE_TYPE.homepage_highlight_1_mobile_banner_image,
							this.bannerLinkLeft,
							leftBannerContent,
						),
						this.addBanner(
							this.topRightBanner,
							BANNER_IMAGE_TYPE.homepage_highlight_2_banner_image,
							this.bannerLinkTopRight,
							topRightBannerContent,
						),
						this.addBanner(
							this.topRightBannerMobile,
							BANNER_IMAGE_TYPE.homepage_highlight_2_mobile_banner_image,
							this.bannerLinkTopRight,
							topRightBannerContent,
						),
						this.addBanner(
							this.bottomRightBanner,
							BANNER_IMAGE_TYPE.homepage_highlight_3_banner_image,
							this.bannerLinkBottomRight,
							bottomRightBannerContent,
						),
						this.addBanner(
							this.bottomRightBannerMobile,
							BANNER_IMAGE_TYPE.homepage_highlight_3_mobile_banner_image,
							this.bannerLinkBottomRight,
							bottomRightBannerContent,
						),
					],

					widget_ids: this.widgets.map((widget) => widget.id),
				};

				this.$emit('onSubmit', data);
			}
		},

		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
	.remove-banners {
		font-size: rem(14);
		color: $color-orange;

		align-items: center;

		cursor: pointer;
	}
</style>
